<template>
  <BaseList
    color="primary"
    :title="title"
    :section="$route.meta.section"
    :headers="headers"
    :items="groups"
    :loading="loading"
    :totalRows="totalRows"
    @add="handleNew"
    @edit="handleEdit"
    @refresh="getData"
    @delete="handleDelete"
    @search="handleSearch"
    @options="handleOptions"
  >
  </BaseList>
</template>

<script>
import BaseList from '@/components/commons/BaseList'
import GroupService from '@/services/group'

export default {
  components: { BaseList },
  props: {
    title: {
      type: String,
      require: true
    },
    admin: {
      type: Boolean,
      default: true
    },
    customer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {},
      selected: [],
      loading: false,
      groups: [],
      totalRows: 0,
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Fecha de Creación', value: 'created_at', width: '10rem' },
        {
          text: 'Status',
          value: 'active',
          class: 'text-left',
          align: 'left',
          width: '10rem'
        }
      ]
    }
  },
  methods: {
    async getData() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.options
      const type = this.customer ? 'Customer' : 'Admin'
      this.loading = true
      try {
        const { data } = await GroupService.fetch(
          { 'filter[name]': this.search, 'filter[type]': type },
          sortBy[0] ? sortBy[0] : 'name',
          sortDesc[0],
          page,
          itemsPerPage == -1 ? this.totalRows : itemsPerPage
        )
        this.groups = data.data
        this.totalRows = data.total
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },

    handleNew() {
      const routerName = this.customer
        ? 'GroupCustomerCreate'
        : 'GroupAdminCreate'
      this.$router.push({ name: routerName })
    },

    handleEdit(item) {
      const routerName = this.customer ? 'GroupCustomerEdit' : 'GroupAdminEdit'
      this.$router.push({
        name: routerName,
        params: { id: item.id }
      })
    },

    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text: '¿ Desea eliminar el registro ?'
      })

      if (!res) return false

      try {
        await GroupService.delete(item.id)
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },

    handleOptions(options) {
      this.options = options
      this.getData()
    },

    handleSearch(value) {
      this.search = value
      this.getData()
    }
  }
}
</script>

<style></style>
