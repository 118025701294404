<template>
  <role-index title="Roles de Clientes" customer />
</template>

<script>
import RoleIndex from '../RoleIndex'
export default {
  components: { RoleIndex }
}
</script>

<style></style>
