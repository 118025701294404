<template>
  <BaseForm
    :title="$route.meta.title"
    :loading="loading"
    @submit="handleSubmit"
  >
    <template #content>
      <v-row>
        <!-- NAME FIELD -->
        <v-col cols="12" md="12">
          <ValidationProvider
            name="Nombre"
            rules="required|min:2|max:150|alpha_spaces"
            v-slot="{ errors }"
          >
            <v-text-field
              id="name"
              label="Nombre"
              hint="Nombre del grupo"
              v-model="group.name"
              autofocus
              dense
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3 class="title">Permisos</h3>
          <v-divider />
          <v-treeview
            id="tree"
            item-key="value"
            v-model="group.permissions"
            :items="sections"
            selectable
            return-object
            open-all
            transition
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-switch
            id="active"
            v-model="group.active"
            color="primary"
            label="Activo"
          />
        </v-col>
      </v-row>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import GroupService from '@/services/group'
import MenuService from '@/services/menu'

export default {
  components: { BaseForm },
  props: {
    admin: {
      type: Boolean,
      default: true
    },
    customer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      sections: [],
      items: [],
      group: { permissions: [], active: true }
    }
  },
  async created() {
    this.loading = true
    try {
      await this.populateMenu()
      const id = this.$route.params.id
      this.group.type = this.customer ? 'Customer' : 'Admin'

      if (id) {
        this.group = await GroupService.find(id)

        const permissions = this.group.permissions.map(item => {
          return {
            value: item.name,
            name: item.name
          }
        })

        this.$set(this.group, 'permissions', permissions)
      }
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await GroupService.save(this.group)
        this.$dialog.notify.success('Registro guardado con éxito.')
        this.$router.go(-1)
      } catch {
        this.$dialog.message.error('No se pudo guardar el registro.')
      }
    },

    async populateMenu() {
      const type = this.customer ? 'Customer' : 'Admin'
      const { data } = await MenuService.fetch({ 'filter[type]': type })
      this.sections = data.data
    }
  }
}
</script>
