<template>
  <role-edit customer />
</template>

<script>
import RoleEdit from '../RoleEdit'
export default {
  components: { RoleEdit }
}
</script>

<style></style>
